import { useAbility } from '@casl/vue'
import { NotificationStore } from '../store/notification.store'
import { AppAbility, Pages } from '@/utils/ability'

export default defineNuxtRouteMiddleware(async (to) => {
    const { can } = useAbility<AppAbility>()
    const { $notificationStore } = useNuxtApp()
    const notificationStore = $notificationStore as NotificationStore

    const restrictedPages = [
        'members',
        'pricing',
        'invoice-history',
        'usage',
        'usage-limit',
    ]

    if (restrictedPages.includes(to.name)) {
        const page = restrictedPages
            .find((page) => page === to.name)
            ?.split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join('')

        if (!can('view', page as Pages)) {
            notificationStore.notifyError({
                summary: 'Only admin can access this page.',
                detail: '',
            })
            return navigateTo('/?forbidden=true')
        }
    }
})
